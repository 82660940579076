<template>
  <div class="pb-12">
    <PageHeader>
      <div class="d-flex align-center justify-space-between space-x-4">
        <h1 class="d-flex align-center mb-1 text-title-1 overflow-hidden">
          <UiBtn icon class="mr-1" @click="goToDashboard">
            <IconArrowLeft width="16" style="margin-top: 2px;"/>
          </UiBtn>
          <v-tooltip top color="rgba(47, 49, 53, 0.7)">
            <template #activator="{attrs, on}">
              <span class="text-truncate" v-bind="attrs"
                    v-on="on">{{ $route.params.project_id ? `Brief for “${form.title}”` : 'Create New Project' }}</span>
            </template>
            <div class="text-captions-1">
              {{ $route.params.project_id ? `Brief for “${form.title}”` : 'Create New Project' }}
            </div>
          </v-tooltip>
        </h1>
      </div>
    </PageHeader>

    <v-container>
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <v-form @submit.prevent="handleSubmit(submitForm)" class="pt-7">
          <v-flex v-if="isLoading" class="d-flex justify-center align-center">
            <v-progress-circular
              :size="100"
              color="accent"
              indeterminate/>
          </v-flex>

          <template v-else>
            <h3 class="mb-2 text-body-lg font-weight-medium gray-100--text">Project Information</h3>
            <v-row class="mb-7">
              <v-col cols="4">
                <ValidationProvider mode="eager" tag="div"
                                    name="title" vid="title" rules="required|min:3|max:100" v-slot="{ errors }">
                  <div class="mb-2 text-body font-weight-light gray-100--text">Project Name</div>
                  <v-text-field
                    v-model="form.title"
                    dense
                    outlined
                    hide-details
                    :disabled="readOnly"
                    placeholder="Enter name"
                    :error="!!errors.length"
                  />
                  <div v-if="errors.length" class="mt-1 text-captions-1 error--text">{{ errors[0] | capitalize }}</div>
                </ValidationProvider>
              </v-col>
              <v-col cols="4">
                <ValidationProvider mode="eager" tag="div" name="category" vid="category"
                                    rules="required"
                                    :custom-messages="customErrorMessages" v-slot="{ errors }">
                  <div class="mb-2 text-body font-weight-light gray-100--text">Category</div>
                  <UiSelect
                    v-model="form.category"
                    :items="getCategories"
                    item-text="title"
                    item-value="id"
                    hide-details
                    :disabled="readOnly"
                    :error="!!errors.length"
                    placeholder="Select category"
                  />
                  <div v-if="errors.length" class="mt-1 text-captions-1 error--text">{{ errors[0] | capitalize }}</div>
                </ValidationProvider>
              </v-col>
              <v-col cols="4">
                <ValidationProvider tag="div" name="tags" vid="tags"
                                    rules="required"
                                    :custom-messages="customErrorMessages" v-slot="{ errors }">
                  <div class="mb-2 text-body font-weight-light gray-100--text">Project Tags</div>
                  <v-combobox
                    v-model="form.tags"
                    :items="selectedCategory.tags"
                    :item-text="'title'"
                    :search-input.sync="searchTag"
                    multiple
                    persistent-hint
                    hide-details
                    hide-selected
                    dense
                    outlined
                    append-icon=""
                    chips
                    :menu-props="{contentClass: 'ui-autocomplete-menu'}"
                    :error="!!errors.length"
                    :disabled="!form.category || readOnly"
                    @change="addCustomTag"
                    placeholder="Select tags"
                    class="tag-select"
                  >
                    <template v-slot:selection="{ attrs, item, parent, selected }">
                      <v-chip
                        v-bind="attrs"
                        :input-value="!item.used_in_style && selected"
                        label
                        outlined
                        color="accent"
                        :disabled="readOnly"
                        small
                      >
                        <button type="button" v-if="!item.used_in_style && !readOnly" class="d-flex mr-2"
                                @click="removeTag(item)">
                          <IconCancelCircle width="12"/>
                        </button>
                        <span>{{ item.title }}</span>
                      </v-chip>
                    </template>
                    <template #append>
                      <IconChevronDown width="16" class="icon-transition" style="margin-top: 1px;"/>
                    </template>
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            No results matching "<strong>{{ searchTag }}</strong>". Press <kbd>enter</kbd> to create a
                            new one
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>
                  <div v-if="errors.length" class="mt-1 text-captions-1 error--text">{{ errors[0] | capitalize }}</div>
                </ValidationProvider>
              </v-col>
            </v-row>
            <h3 class="mb-1 text-body-lg font-weight-medium gray-100--text">Jewelry Type</h3>
            <div class="mb-7 text-body font-weight-light gray-100--text">Select a type from below or click on other if
              your item is not on the list
            </div>
            <UiJewelryTypesGroup v-model="form.product_type_id" :disabled="readOnly"
                                 :list="selectedCategory.product_types" class="mb-10"/>


            <template v-if="!isUpdated">
              <h3 class="mb-1 text-body-lg font-weight-medium gray-100--text">How you want to provide project
                details?</h3>
              <v-radio-group v-model="radios" class="d-inline-block mb-10" :disabled="filesLoading || readOnly">
                <UiRadio label="I prefer to fill In information manually"
                         value="manual"/>
                <UiRadio label="I want to upload project specifications files"
                         value="default"/>
              </v-radio-group>
            </template>

            <template v-if="radios === 'manual'">
              <div class="manual-wrap pa-5 mb-7">
                <h3 class="mb-5 text-body-lg font-weight-medium gray-100--text">Manual Specifications</h3>
                <IntakeForm :form-schema="intakeForm" :disabled="readOnly"
                            :defaultForm="project && project.product_type_id === form.product_type_id ? project.intake_data.dataset : {}"
                            @changeForm="setIntakeData"/>
              </div>
              <h3 class="text-body-lg mb-2 font-weight-medium gray-100--text">Project Brief</h3>
              <div class="mb-7 text-body font-weight-light gray-100--text">Any Additional information about the
                project
              </div>
              <v-textarea
                v-model="form.description"
                dense
                outlined
                rows="3"
                hide-details
                no-resize
                :disabled="readOnly"
                placeholder="Any additional information"
                class="textarea mb-7"
              />
              <h3 class="text-body-lg mb-2 font-weight-medium gray-100--text">Attachments</h3>
              <div class="mb-7 text-body font-weight-light gray-100--text">
                {{
                  isUpdated ? 'Upload any additional relevant files to serve as guidelines for the project. You can check your uploaded files below' : 'Upload any additional relevant files to serve as guidelines for the project.'
                }}
              </div>
              <UploadAttachments @successUpload="successUpload" @deleteFile="onFileDelete" :disabled="readOnly" v-model="form.attachments" v-bind="formUploadTitles"
                                 @fileLoading="filesLoading = $event" @downloadAll="downloadAttachments"
                                 :show-download="!!($route.params.project_id && project.attachments.length)" :download-loading="downloadLoading"/>

            </template>
            <template v-else>
              <h3 class="mb-5 text-body-lg font-weight-medium gray-100--text">Upload your project specifications</h3>
              <UploadAttachments @successUpload="successUpload" @deleteFile="onFileDelete" :disabled="readOnly" v-model="form.attachments" v-bind="formUploadTitles"
                                 @fileLoading="filesLoading = $event" @downloadAll="downloadAttachments"
                                 :show-download="!!($route.params.project_id && project.attachments.length)" :download-loading="downloadLoading"/>
              <div class="mt-8 mb-2 text-body font-weight-light gray-100--text">Project Brief / Special Instructions
              </div>
              <v-textarea
                v-model="form.description"
                dense
                outlined
                rows="3"
                :disabled="readOnly"
                hide-details
                no-resize
                placeholder="Any additional information about them"
                class="textarea"
              />
            </template>

            <div class="d-flex align-center justify-end mt-14" v-if="!readOnly">
              <UiBtn width="126" color="accent" type="submit" :disabled="filesLoading">
                {{ isUpdated ? 'Update' : 'Create Project' }}
              </UiBtn>
            </div>

          </template>
        </v-form>

      </ValidationObserver>
    </v-container>

    <CreateTemplateModal/>
  </div>
</template>

<script>
import UiBtn from "@/components/UI/UiBtn.vue";
import PageHeader from "@/components/layout/PageHeader.vue";
import CreateTemplateModal from "@/views/project/specification/CreateTemplateModal.vue";
import {mapActions, mapGetters} from "vuex";
import UiSelect from "@/components/UI/UiSelect.vue";
import UiJewelryTypesGroup from "@/components/UI/UiJewelryTypesGroup.vue";
import UiRadio from "@/components/UI/UiRadio.vue";
import UploadAttachments from "@/views/project/create-or-edit/UploadAttachments.vue";
import IntakeForm from "@/views/project/create-or-edit/IntakeForm.vue";

export default {
  name: "ProjectCreateEdit",
  components: {
    IntakeForm,
    UploadAttachments,
    IconArrowLeft: () => import('@/components/icons/IconArrowLeft'),
    IconCancelCircle: () => import('@/components/icons/IconCancelCircle'),
    IconChevronDown: () => import('@/components/icons/IconChevronDown'),
    UiRadio,
    UiJewelryTypesGroup,

    UiSelect,
    CreateTemplateModal,
    PageHeader,
    UiBtn
  },
  data() {
    return {
      isLoading: false,
      filesLoading: false,
      downloadLoading: false,
      customErrorMessages: {
        required: 'please select {_field_}'
      },
      searchTag: '',
      radios: 'manual',
      project: null,
      form: {
        title: 'My project',
        category: null,
        tags: [],
        description: '',
        attachments: [],
        product_type_id: null,
        intake_data: {}
      },
    }
  },
  computed: {
    ...mapGetters([
      'getPermission',
      'getCategories',
      'getActiveSpace',
      'getActiveFolder'
    ]),
    isUpdated() {
      return !!this.$route.params.project_id
    },
    selectedCategory() {
      if (!this.form.category) {
        return []
      }

      return this.getCategories.filter((category) => {
        return category.id === this.form.category;
      })[0]
    },

    intakeForm() {
      return this.selectedCategory?.product_types?.find?.(type => type.id === this.form.product_type_id)
    },

    formUploadTitles() {
      switch (this.radios) {
        case "default":
        default:
          return {
            title: 'Project Specifications',
            subtitle: 'These are the files you’ve uploaded for designers to follow',
            loadLabel: '+Add More',
            emptyFirstText: 'Drag and Drop here',
            emptySecondText: 'or browse to add',
          }
        case 'manual':
          return {
            title: 'General Attachments',
            subtitle: 'These are the files you’ve uploaded for designers to follow',
            loadLabel: 'Add additional files',
            emptyFirstText: 'Upload any related files here and we\'ll take care of them.',
            emptySecondText: 'browse to add',
          }
      }
    },

    readOnly() {
      return this.isUpdated ? !this.getPermission(this.$route.params.project_id).specification['can-edit-specification'] : false
    }
  },
  created() {
    this.getData();
  },
  methods: {
    ...mapActions([
      'updateProject',
    ]),
    onFileDelete(file_id) {
      if (!this.$route.params.project_id) return
      this.project.attachments = this.project.attachments.filter(file => file.id !== file_id)
    },
    async successUpload() {
      if (!this.$route.params.project_id) return
      try {
        const {data: project} = await this.$api.project.update(this.$route.params.project_id, {attachments: this.form.attachments.map(file => file.id)});

        // this.form.attachments = project.attachments.map(file => ({
        //   ...file,
        //   isAvatar: file.id === project.file_id
        // }))

        this.project = project
      } catch (error) {
        console.error(error);
      }
    },
    goToDashboard() {
      if(this.$route.params.project_id){
        this.$router.push({name: 'ProjectDesign', params: {project_id: this.$route.params.project_id}})
      } else if (this.getActiveSpace?.id) {
        this.$router.push({name: 'Space', params: {id: this.getActiveSpace?.id}})
      } else {
        this.$router.push({name: 'Dashboard'})
      }
    },
    removeTag(item) {
      this.form.tags.splice(this.form.tags.indexOf(item), 1)
      this.form.tags = [...this.form.tags]
    },

    addCustomTag(item) {
      let customTags = item.filter(i => {
        return typeof i === "string"
      })

      if (customTags.length) {
        this.$api.tag.create({title: customTags[0]})
          .then((response => {
            this.form.tags.splice(this.form.tags.indexOf(customTags), 1)
            this.form.tags.push(response)
          }))
      }

      const usedTags = this.project?.tag ? this.project.tag.filter(tag => tag.used_in_style) : []

      this.form.tags = [...usedTags, ...this.form.tags.filter(tag => !tag.used_in_style)]
    },
    async getData() {
      this.isLoading = true;
      if(this.$route.query.folder_id) {
        await this.$store.dispatch('setActiveFolder', this.$route.query.folder_id)
      }

      const requestList = [this.$api.category.list(), this.$api.tag.list()]
      if (this.$route.params.project_id) {
        requestList.push(this.$api.project.get(this.$route.params.project_id))
      }
      try {
        const [categories, tags, project] = await Promise.all(requestList)
        await this.$store.dispatch('setCategories', categories.data);
        this.form.category = this.getCategories?.[0]?.id
        await this.$store.dispatch('setUserTags', tags);

        if (project) {
          this.form.title = project.data.title
          this.form.category = project.data.category[0].id
          this.form.tags = [...project.data.tag]
          this.form.description = project.data.description
          this.form.attachments = project.data.attachments.map(file => ({
            ...file,
            isAvatar: file.id === project.data.file_id,
          }))
          this.form.product_type_id = project.data.product_type_id
          this.form.intake_data = project.data.intake_data.dataset
          this.radios = project.data.manual_specification ? 'manual' : 'default'
          this.project = project.data

          if (project.data.space_id) {
            await this.$store.dispatch('setActiveSpace', project.data.space_id)
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    setIntakeData(form) {
      this.form.intake_data = form
    },
    submitForm() {
      if (this.readOnly) return
      const thumbFile = this.form.attachments.find(file => file.isAvatar)
      const params = {
        title: this.form.title,
        description: this.form.description,
        category: this.form.category,
        product_type_id: this.form.product_type_id,
        manual_specification: this.radios === 'manual',
        intake_data: this.form.intake_data,
        tags: this.form.tags.map((i) => {
          return i.id
        }),
        space_id: this.getActiveSpace ? this.getActiveSpace.id : null,
        folder_id: this.getActiveFolder ? this.getActiveFolder.id : null,
        file_id: thumbFile ? thumbFile.id : null,
        attachments: this.form.attachments.map(file => file.id)
      }
      if (this.$route.params.project_id) {
        this.update(params)
      } else {
        this.create(params)
      }
    },
    async create(params) {
      this.isLoading = true;

      try {
        const {data: project} = await this.$api.project.create({...params, status: this.$config.project.status.draft});

        this.$toast.open({
          message: `Congratulations,you have successfully <br> created your project.`,
          type: 'success',
          position: 'top-right'
        });

        const newPermissions = await this.$api.project.permissions(this.getActiveSpace?.id);
        await this.$store.dispatch('setPermissions', newPermissions.data);
        const {approved} = this.$config.project.status;
        const {moodBoard, design} = this.$config.project.moduleTypes;
        const designModules = project.modules.filter(module => module.type === design);
        if (project.modules[0].type === moodBoard && project.modules[0].status !== approved || !designModules.length) {
          await this.$api.moodBoard.skip(project.id);
          await this.$router.push({name: 'ProjectDesign', params: {project_id: project.id}});
        } else {
          await this.$router.push({
            name: 'ProjectDesign',
            params: {project_id: project.id},
          });
        }
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;

        // Catch Validation
        this.$nextTick(() => {
          if (error.response.status === this.$config.http.UNPROCESSABLE_ENTITY) {
            this.$refs.form.setErrors(error.response.data);
          }
        })

        console.error(error);
      }
    },
    async update(params) {
      this.isLoading = true;

      try {
        const {data: project} = await this.$api.project.update(this.$route.params.project_id, params);

        this.form.attachments = project.attachments.map(file => ({
          ...file,
          isAvatar: file.id === project.file_id
        }))

        this.project = project

        await this.updateProject({modules: project.modules});
        this.$toast.open({
          message: 'You successfully updated project data',
          type: 'success',
          position: 'top-right'
        });
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;

        // Catch Validation
        this.$nextTick(() => {
          this.form.tags = [...this.project.tag]
          if (error.response.status === this.$config.http.UNPROCESSABLE_ENTITY) {
            this.$refs.form.setErrors(error.response.data);
          }
        })
        console.error(error);
      }
    },
    async downloadAttachments() {
      this.downloadLoading = true
      try {
        const files = await this.$api.project.downloadsAttachmentsFiles(this.$route.params.project_id)

        const fileURL = URL.createObjectURL(new Blob([files]));

        const anchor = document.createElement('a');
        anchor.href = fileURL;
        anchor.download = `${this.project.title}-attachments.zip`;

        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);

        URL.revokeObjectURL(fileURL);
      } catch (error) {
        if (error.response.status === this.$config.http.NOT_FOUND) {
          this.$toast.open({
            message: 'There are no files',
            type: 'error',
            position: 'top-right'
          });
        } else {
          this.$toast.open({
            message: 'Oops! Something went wrong!',
            type: 'error',
            position: 'top-right'
          });
        }
      } finally {
        this.downloadLoading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
:deep(.tag-select) {
  &.v-input--is-focused {
    .v-input__append-inner svg {
      transform: rotate(-180deg);
    }
  }

  .v-input__slot {
    padding-right: 12px !important;

    input {
      max-height: inherit !important;
      padding: 2px 0 !important;
    }
  }

  &.v-input--is-dirty .v-input__slot {
    padding-left: 2px !important;
  }

  .v-select__selections {
    min-height: 28px !important;
    padding: 0 !important;
  }

  .v-chip {
    height: 20px;
    padding: 0 8px;
    margin: 2px 4px;

    button {
      margin-right: 6px;
    }
  }
}

.manual-wrap {
  border: 1px solid var(--v-gray-30-base);
}
</style>
